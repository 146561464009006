<template>
  <span :class="['px-8 rounded-full flex items-center justify-center text-xs font-semibold uppercase', getStatus]">
    <template v-if="!isSlotMessage">{{ statusMessage }}</template>
    <slot />
  </span>
</template>

<script>
export default {
  name: 'ElStatus',

  props: {
    status: { type: String, default: '' }
  },

  data () {
    return {
      statuses: {
        open: 'Åben ',
        sent: 'Sendt',
        fulfilled: 'Afsendt',
        partially_fulfilled: 'Delvist afsendt',
        closed: 'Lukket',
        inapproval: 'Under godkendelse',
        approved: 'Godkendt',
        published: 'Publiseret',
        archived: 'Ikke aktivt',
        partially_refunded: 'Delvist refunderet',
        refunded: 'Refunderet'
      }
    }
  },

  computed: {
    getStatus () {
      if (this.status) {
        return this.status.toLowerCase().split(' ').join('_')
      }
      return ''
    },

    statusMessage () {
      return this.statuses[this.getStatus] || ''
    },

    isSlotMessage () {
      return Object.prototype.hasOwnProperty.call(this.$slots, 'default')
    }
  }
}
</script>

<style lang="scss" scoped>
.open, .published {
  @apply text-green-600 bg-green-100;
}
.sent {
  @apply text-green-600 bg-grey-100
}
.fulfilled, .refunded {
  @apply text-green-600 bg-grey-100
}
.partially_fulfilled {
  @apply text-green-600 bg-grey-100
}
.closed {
  @apply text-green-600 bg-grey-100
}
.paid, .inapproval {
  @apply text-green-600 bg-yellow-100
}
.archived {
  @apply text-green-600
}
</style>
